import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"
import PortfolioMenu from "../components/portfolio-menu"

import "../utils/normalize.css"
import "./../utils/css/screen.css"

const Fab = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO title="LC" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            fabrication
          </h2>
        </header>
      )}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {tags: {in: ["fabrication"]}}}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

const FabShowcase = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Fab location={props.location} props data={data} {...props} />
    )}
  />
)

export default FabShowcase 
